/* eslint-disable @typescript-eslint/no-unused-vars */
import { definition, EntityMeta, getStandardApi, getStandardUrls, T } from 'core/module';

import { CostDetail, CostGrid } from './models/Cost';

export const financeEntities = {
  cost: {
    ...definition<CostGrid, CostDetail>('costs', {
      name: (t: T) => t('Costs'),
      listName: (t: T) => t('Costs'),
    }),
    urls: (_?: string) => ({
      ...getStandardUrls('costs'),
      projects: '/costs/projects',
    }),
    api: () => ({
      ...getStandardApi('costs'),
      clusters: (id?: string | number) => (id ? `/costs/clusters/${id}` : '/costs/clusters'),
      categories: (id?: string | number) => (id ? `/costs/categories/${id}` : '/costs/categories'),
    }),
  },
  businessPlan: {
    ...definition<CostGrid, CostDetail>('costs', {
      name: (t: T) => t('Costs'),
      listName: (t: T) => t('Costs'),
      permission: 'costs',
    }),
    urls: (_?: string) => ({
      ...getStandardUrls('business-plans'),
      overview: (id?: number | string) => `/business-plans/${id}`,
      detail: (id: number | string) => `/business-plans/${id}/detail`,
      balanceSheet: (id: number | string) => `/business-plans/${id}/balanceSheet`,
      bankPortfolio: (id: number | string) => `/business-plans/${id}/bankPortfolio`,
      settings: (id: number | string) => `/business-plans/${id}/settings`,
    }),
    api: () => ({
      ...getStandardApi('business-plans'),
      reduction: (id: string) => `/business-plans/reductions/${id}`,
      totalRevenue: (id: string) => `/business-plans/revenue/total/${id}`,
      forecastedRevenue: (id: string) => `/business-plans/revenue/forecasted/${id}`,
      eat: (id: string) => `/business-plans/revenue/eat/${id}`,
      totalCosts: (id: string) => `/business-plans/costs/total/${id}`,
      cpww: (id: string) => `/business-plans/costs/cpww/${id}`, // Costs per Working Week
      cmavg: (id: string) => `/business-plans/costs/cmavg/${id}`, // Costs moving average
      netpm: (id: string) => `/business-plans/revenue/netpm/${id}`, // Net Profit Margin
      balanceSheet: (id: string) => `/business-plans/balance-sheets/${id}`,
      overview: (id: string) => `/business-plans/overview/${id}`,
    }),
  },
} as const;

// REQUIRED !!!
// @ts-ignore
const verifyEntities: Record<keyof typeof financeEntities, EntityMeta> = financeEntities;

export type K = keyof typeof financeEntities;
